/**
 * Error response received from an API consumed by this application.
 */
export interface ApiError {
  error: string;
  error_description?: string;
  error_uri?: string;
  [additionalProperty: string]: any;
}

export function isInstanceOfApiError(obj: any): obj is ApiError {
  return "error" in obj;
}

export class InternalApiError extends Error implements ApiError {
  public error: string;
  public error_description?: string;
  public error_uri?: string;

  constructor(error: string, error_description?: string, error_uri?: string) {
    super(error_description);
    this.error = error;
    this.error_description = error_description;
    this.error_uri = error_uri;
  }
}
