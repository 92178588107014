import { APP_ORGS } from "./appOrganizations";
import {
  ORG_1_ROLES,
  ORG_1_ROLE_MEMBER_IDS_BY_ROLE_ID,
  ORG_1_ROLE_PERMISSION_IDS_BY_ROLE_ID
} from "./org1Roles";
import {
  ORG_2_ROLES,
  ORG_2_ROLE_MEMBER_IDS_BY_ROLE_ID,
  ORG_2_ROLE_PERMISSION_IDS_BY_ROLE_ID
} from "./org2Roles";

export const ALL_ROLES = [...ORG_1_ROLES, ...ORG_2_ROLES];

export const ALL_ROLES_BY_ORG_ID = {
  [APP_ORGS[0].id as string]: ORG_1_ROLES,
  [APP_ORGS[1].id as string]: ORG_2_ROLES
};

export const ALL_ROLE_MEMBER_IDS_BY_ROLE_ID = {
  ...ORG_1_ROLE_MEMBER_IDS_BY_ROLE_ID,
  ...ORG_2_ROLE_MEMBER_IDS_BY_ROLE_ID
};

export const ALL_ROLE_PERMISSION_IDS_BY_ROLE_ID = {
  ...ORG_1_ROLE_PERMISSION_IDS_BY_ROLE_ID,
  ...ORG_2_ROLE_PERMISSION_IDS_BY_ROLE_ID
};
