import { InternalPermission } from "../../../model/InternalPermission";

export const PERMISSION_ANY: InternalPermission = {
  id: "a33e8315-9ef2-49b5-9647-7d8a2961bd6e",
  authorId: "66ac87f6-4eca-4b40-8e7c-3d6dbb7f0b5d",
  created: "2020-04-16T09:59:00.000Z",
  name: "*"
};

const PERMISSION_ORGANIZATION: InternalPermission = {
  id: "ca8ad927-934c-41c9-a2b1-9198a0b70dd2",
  authorId: "66ac87f6-4eca-4b40-8e7c-3d6dbb7f0b5d",
  created: "2020-04-16T10:01:00.000Z",
  name: "Organization"
};

const PERMISSION_PROFILE: InternalPermission = {
  id: "a5b603f0-c671-499c-988a-fe9310d65af0",
  authorId: "66ac87f6-4eca-4b40-8e7c-3d6dbb7f0b5d",
  created: "2020-04-16T10:00:00.000Z",
  name: "Profile"
};

export const INTERNAL_PERMISSIONS = [
  PERMISSION_ANY,
  PERMISSION_ORGANIZATION,
  PERMISSION_PROFILE
];
