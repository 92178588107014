import * as ActionTypes from "./actionTypes";
import { ActionSender } from "../model/ActionSender";
import { generateRandomString } from "../util/random";
import { ApiError } from "../model/ApiError";
import { AppAction } from "./actionTypes";

function generateErrorId(): string {
  return new Date().getTime() + "-" + generateRandomString(8);
}

export function addError<A extends AppAction>(
  sender: ActionSender,
  error: string,
  operationType: string,
  action?: A,
  errorDescription?: string,
  errorUri?: string,
  errorId: string = generateErrorId()
): ActionTypes.AddErrorAction<A> {
  return {
    type: ActionTypes.ADD_ERROR,
    error: {
      errorId,
      operationType,
      apiError: {
        error: error,
        error_description: errorDescription,
        error_uri: errorUri
      },
      actionSender: sender,
      action
    }
  };
}

export function addApiError<A extends AppAction>(
  sender: ActionSender,
  operationType: string,
  apiError: ApiError,
  action?: A,
  errorId: string = generateErrorId()
): ActionTypes.AddErrorAction<A> {
  return {
    type: ActionTypes.ADD_ERROR,
    error: {
      errorId,
      operationType,
      apiError,
      actionSender: sender,
      action
    }
  };
}

export function clearError(errorId: string): ActionTypes.ClearErrorAction {
  return {
    type: ActionTypes.CLEAR_ERROR,
    errorId
  };
}

export function clearAllErrors(): ActionTypes.ClearAllErrorsAction {
  return {
    type: ActionTypes.CLEAR_ALL_ERRORS
  };
}
