import * as ActionTypes from "../actions/actionTypes";
import { LogoutCompleted } from "../model/LogoutCompleted";

export default function logoutCompleted(
  state: LogoutCompleted,
  action: ActionTypes.AppAction
): LogoutCompleted | null {
  switch (action.type) {
    case ActionTypes.SET_LOGOUT_COMPLETED:
      const setLogoutCompletedAction = action as ActionTypes.SetLogoutCompletedAction;
      return setLogoutCompletedAction.logoutCompleted;
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_AUTHN:
    case ActionTypes.CLEAR_AUTHN_STATUS:
      return null;
    default:
      return state || null;
  }
}
