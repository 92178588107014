import * as ActionTypes from "../actions/actionTypes";

export default function appOrganizationIds(
  state: string[],
  action: ActionTypes.AppAction
): string[] | null {
  switch (action.type) {
    case ActionTypes.LOAD_ORGS:
      const loadOrgsCompleted = action as ActionTypes.LoadOrgsAction;
      return loadOrgsCompleted.organizations.map(org => org.id as string);
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
