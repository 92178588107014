import { Authentication } from "../model/Authentication";
import { ActionSender } from "../model/ActionSender";

/**
 * Action sender values to use by authentication, especially when communicating
 * errors.
 */
export const SENDER_KEY_AUTHENTICATION = "AUTHENTICATION";
export const SENDER_AUTHENTICATION: ActionSender = {
  key: SENDER_KEY_AUTHENTICATION
};

/**
 * Checks if user has currently valid authentication and authorization.
 * @param authentication Authentication state
 */
export function isAuthenticatedAndAuthorized(
  authentication?: Authentication
): boolean {
  if (authentication === undefined || authentication === null) {
    return false;
  }

  if (
    !(
      isAuthenticationValidStrict(authentication) ||
      isAuthenticationValidLenient(authentication)
    )
  ) {
    return false;
  }

  return isAccessTokenValid(authentication);
}

/**
 * Checks if access token received from the server is valid.
 * @param authentication Authentication state
 */
export function isAccessTokenValid(authentication: Authentication): boolean {
  const now = new Date().getTime();
  const expires =
    authentication.accessTokenReceived +
    authentication.accessTokenExpiresIn * 1000;
  return now >= authentication.accessTokenReceived && now <= expires;
}

/**
 * Checks if the given authentication is currently valid, requiring that clock
 * on the client is in sync with the server clock.
 * @param authentication Authentication state
 */
export function isAuthenticationValidStrict(
  authentication: Authentication
): boolean {
  const now = new Date().getTime();
  return (
    now >= authentication.authnIssued && now <= authentication.authnExpires
  );
}

/**
 * Checks if the given authentication is currently valid, adjusting clock by
 * using authentication and access token received by the client timestamp.
 * @param authentication Authentication state
 */
export function isAuthenticationValidLenient(
  authentication: Authentication
): boolean {
  const now = new Date().getTime();
  const delta = authentication.accessTokenReceived - authentication.authnIssued;
  const expires = authentication.authnExpires + delta;
  return now >= authentication.accessTokenReceived && now <= expires;
}
