import * as ActionTypes from "../actions/actionTypes";
import { OrgGroupInvitationIdsByOrgId } from "../store/OrganizationInvitationState";

export default function orgOrgInvitationIds(
  state: OrgGroupInvitationIdsByOrgId,
  action: ActionTypes.AppAction
): OrgGroupInvitationIdsByOrgId | null {
  const currentState = state || ({} as OrgGroupInvitationIdsByOrgId);
  switch (action.type) {
    case ActionTypes.LIST_ORG_ORG_GROUP_INVITATIONS:
      const listOrgInvitations = action as ActionTypes.ListOrgOrgGroupInvitationsAction;
      const newOrgInvitationIds = listOrgInvitations.invitations.map(
        invitation => invitation.id as string
      );
      return {
        ...currentState,
        [listOrgInvitations.orgId]: newOrgInvitationIds
      };
    case ActionTypes.CREATE_ORG_GROUP_INVITATION:
      const createOrgInvitation = action as ActionTypes.CreateOrgGroupInvitationAction;
      const beforeCreate = currentState[createOrgInvitation.orgId] || [];
      const afterCreate = [
        ...beforeCreate,
        createOrgInvitation.invitation.id as string
      ];
      return {
        ...currentState,
        [createOrgInvitation.orgId]: afterCreate
      };
    case ActionTypes.DELETE_ORG_GROUP_INVITATION:
      const delOrgInvitation = action as ActionTypes.DeleteOrgGroupInvitationAction;
      return {
        ...currentState,
        [delOrgInvitation.orgId]: (
          currentState[delOrgInvitation.orgId] || []
        ).filter(invitationId => invitationId !== delOrgInvitation.invitationId)
      };
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
