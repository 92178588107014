import { OrganizationRole } from "../../../model/OrganizationRole";
import { ORG_2_USERS } from "./org2Members";
import { PERMISSION_ANY } from "./permissions";

/*
 * Organization roles of mock org 2, "Acne Ltd".
 */

const ORG_2_ROLE_ADMIN: OrganizationRole = {
  id: "cf54c6db-66ce-41f0-ab99-3901e2456d94",
  designator: "orgadmin",
  authorId: "e31bc284-34e0-490f-9c6a-2f2a570751b1",
  created: "2020-04-15T11:00:00.000Z",
  name: "OrgAdmins of Acne Ltd",
  description: "Organization administrators"
};

const INITIAL_ORG_2_ROLE_ADMIN_MEMBER_IDS: string[] = [
  ORG_2_USERS[0].id as string
];

const INITIAL_ORG_2_ROLE_ADMIN_PERMISSION_IDS: {
  id: string;
  actions: string[];
}[] = [{ id: PERMISSION_ANY.id as string, actions: ["*"] }];

export const ORG_2_ROLES: OrganizationRole[] = [ORG_2_ROLE_ADMIN];

export const ORG_2_ROLE_MEMBER_IDS_BY_ROLE_ID = {
  [ORG_2_ROLE_ADMIN.id as string]: INITIAL_ORG_2_ROLE_ADMIN_MEMBER_IDS
};

export const ORG_2_ROLE_PERMISSION_IDS_BY_ROLE_ID = {
  [ORG_2_ROLE_ADMIN.id as string]: INITIAL_ORG_2_ROLE_ADMIN_PERMISSION_IDS
};
