import { InternalPermission } from "./InternalPermission";
import { PermissionWithGrantedActions } from "./PermissionWithGrantedActions";

export interface InternalPermissionWithGrantedActions
  extends InternalPermission,
    PermissionWithGrantedActions {}

export function toInternalPermission(
  permWithActions: InternalPermissionWithGrantedActions
): InternalPermission {
  const { grantedActions, ...other } = permWithActions;
  return other;
}
