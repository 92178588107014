import { Organization } from "../../../model/Organization";

export const APP_ORG_1: Organization = {
  id: "a43bef78-630f-4230-9cc2-dc04311d491f",
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-04-08T08:00:00.000Z",
  description: "Org 1 that can be managed in OrgAdmin",
  editorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  modified: "2020-04-08T08:00:00.000Z",
  name: "Acme Inc",
  type: "Mock"
};

export const APP_ORG_2: Organization = {
  id: "3f9b7916-a472-4409-b439-eebc42b1f1bd",
  authorId: "6bc1d95f-81c8-439c-b6d5-e60a9f6ae149",
  created: "2020-04-08T07:00:00.000Z",
  description: "Org 2 that can be managed in OrgAdmin",
  name: "Very Big Corp. of America",
  type: "Mock"
};

export const APP_ORGS: Organization[] = [APP_ORG_1, APP_ORG_2];
