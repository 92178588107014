/**
 * Applies throttle on given function. The wrapped function will be called only
 * after throttle delay period. The wrapped function is never called immediately
 * (as indicated by "lazy"). If the returned throttle is called several times
 * before throttle delay has expired, the wrapped function is eventually called
 * with the latest arguments given to the throttle.
 * @param func The function to call.
 * @param delay The throttle delay in milliseconds.
 */
export function lazyThrottle(
  this: any,
  func: (...args: any[]) => any,
  delay: number
): (...args: any[]) => void {
  let enableCall = true;
  let latestArgs: any[];

  return (...args) => {
    latestArgs = args;
    if (!enableCall) return;

    enableCall = false;
    setTimeout(() => {
      enableCall = true;
      func.apply(this, latestArgs);
    }, delay);
  };
}
