import { LicenseUser } from "./LicenseUser";
import { LicenseAssignmentWithSessions } from "./LicenseAssignmentWithSessions";

export type LicenseUserWithAssignmentsAndSessions = LicenseUser & {
  assignments?: LicenseAssignmentWithSessions[];
};

export function isInstanceOfLicenseUserWithAssignmentsAndSessions(
  obj: LicenseUser
): obj is LicenseUserWithAssignmentsAndSessions {
  return "assignments" in obj;
}
