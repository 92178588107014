import { APP_ORG_1, APP_ORG_2 } from "./appOrganizations";
import {
  ORG_1_LICENSES,
  ORG_1_LICENSE_USERS_BY_LICENSE_ID
} from "./org1Licenses";
import {
  ORG_2_LICENSES,
  ORG_2_LICENSE_USERS_BY_LICENSE_ID
} from "./org2Licenses";

export const ORG_LICENSES = {
  [APP_ORG_1.id as string]: ORG_1_LICENSES,
  [APP_ORG_2.id as string]: ORG_2_LICENSES
};

export const ORG_LICENSE_USERS_BY_LICENSE_ID = {
  [APP_ORG_1.id as string]: ORG_1_LICENSE_USERS_BY_LICENSE_ID,
  [APP_ORG_2.id as string]: ORG_2_LICENSE_USERS_BY_LICENSE_ID
};
