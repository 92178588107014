import { Jose } from "jose-jwe-jws";

/**
 * X.509 certificate for verifying mock id token signatures.
 * openssl req -x509 -newkey rsa:2048 -keyout key.pem -out cert.pem -days 36500 -nodes
 *
const MOCK_IDP_CERT =
  "-----BEGIN CERTIFICATE-----" +
  "MIIDkzCCAnugAwIBAgIJAIHhSqOSRhWSMA0GCSqGSIb3DQEBCwUAMF8xCzAJBgNV" +
  "BAYTAkZJMRMwEQYDVQQIDApTb21lLVN0YXRlMQ8wDQYDVQQKDAYxMER1a2UxEDAO" +
  "BgNVBAsMB1Rlc3RpbmcxGDAWBgNVBAMMD3Rlc3QuMTBkdWtlLmNvbTAgFw0yMDAz" +
  "MjUwNjU4MThaGA8yMTIwMDMwMTA2NTgxOFowXzELMAkGA1UEBhMCRkkxEzARBgNV" +
  "BAgMClNvbWUtU3RhdGUxDzANBgNVBAoMBjEwRHVrZTEQMA4GA1UECwwHVGVzdGlu" +
  "ZzEYMBYGA1UEAwwPdGVzdC4xMGR1a2UuY29tMIIBIjANBgkqhkiG9w0BAQEFAAOC" +
  "AQ8AMIIBCgKCAQEAz3iSskkRv/ASMLkaOTxfGd9N0wYDE0jH1bi2rZCM88a2kDxk" +
  "HoyE6Mg+PyMp3tX4O75puETvINVeVk+6+Fo0XgU9+EH1FuKMZHz0jNetLWEUqi/H" +
  "PzKQpbd50mHv7b+VZguaOd5Bgrfs63aQP2sMB40e9pWzDF/DzlP0t4l5ZMLUrPth" +
  "jWM2CIkT8zbAYrU/9gdNTgja2DZekqp15Veg1OodewSiEyRh/fj+//e8zoeSjuOl" +
  "rzDTgpdJ3w9Lmull5mQbWYoHlxh+2vOF1LTKymIuFS/6zOGsy68vb3ezgGOXPpOO" +
  "CaY+6zyBVFY8SNl8CuNarR9aUvl+InLu8DfFGQIDAQABo1AwTjAdBgNVHQ4EFgQU" +
  "ee6tdrafQZ/Hv1At55tIBGu+DUUwHwYDVR0jBBgwFoAUee6tdrafQZ/Hv1At55tI" +
  "BGu+DUUwDAYDVR0TBAUwAwEB/zANBgkqhkiG9w0BAQsFAAOCAQEAKx7MiS8Iwkyy" +
  "9kEWpyKNuBhkJb2PHlLHuTNkF91vm3qxLl4gYbUhlobgV4yRPutT/zdEGHQ/dfxc" +
  "LqAymkERhVgcqonKtftrLhsq/faTCqOT1Yki3ZAJtRf/pVA2snFkU+Vuh7QO1u4g" +
  "ZpIyfY4SzATxyncctsvOUmOj4bz37V+eI9a0oKUCRE36i2anrloIJppbpppnrBFv" +
  "4bNQJ9vroGcyFLR9DhDLWRi10xwPWoTK9NcjnkfIw+O2Ax727wahXB4aAgDLwejM" +
  "2uyi7hgxSAOTJgTtcNZRTrEVUqy7um7yOBedIyLzAoHt2aN6W6RkhkADps9L7u5a" +
  "nw49szMXJQ==" +
  "-----END CERTIFICATE-----";
  */

/**
 * Public key extracted from X.509 certificate for verifying mock id token signatures.
 * openssl req -x509 -newkey rsa:2048 -keyout key.pem -out cert.pem -days 36500 -nodes
 * pem-jwk pubkey.pem > pubkey.jwk
 *
const MOCK_IDP_PUBKEY_PEM =
  "-----BEGIN PUBLIC KEY-----" +
  "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAz3iSskkRv/ASMLkaOTxf" +
  "Gd9N0wYDE0jH1bi2rZCM88a2kDxkHoyE6Mg+PyMp3tX4O75puETvINVeVk+6+Fo0" +
  "XgU9+EH1FuKMZHz0jNetLWEUqi/HPzKQpbd50mHv7b+VZguaOd5Bgrfs63aQP2sM" +
  "B40e9pWzDF/DzlP0t4l5ZMLUrPthjWM2CIkT8zbAYrU/9gdNTgja2DZekqp15Veg" +
  "1OodewSiEyRh/fj+//e8zoeSjuOlrzDTgpdJ3w9Lmull5mQbWYoHlxh+2vOF1LTK" +
  "ymIuFS/6zOGsy68vb3ezgGOXPpOOCaY+6zyBVFY8SNl8CuNarR9aUvl+InLu8DfF" +
  "GQIDAQAB" +
  "-----END PUBLIC KEY-----";
  */
const MOCK_IDP_PUBKEY_JWK: any = {
  kty: "RSA",
  n:
    "z3iSskkRv_ASMLkaOTxfGd9N0wYDE0jH1bi2rZCM88a2kDxkHoyE6Mg-PyMp3tX4O75puETvINVeVk-6-Fo0XgU9-EH1FuKMZHz0jNetLWEUqi_HPzKQpbd50mHv7b-VZguaOd5Bgrfs63aQP2sMB40e9pWzDF_DzlP0t4l5ZMLUrPthjWM2CIkT8zbAYrU_9gdNTgja2DZekqp15Veg1OodewSiEyRh_fj-__e8zoeSjuOlrzDTgpdJ3w9Lmull5mQbWYoHlxh-2vOF1LTKymIuFS_6zOGsy68vb3ezgGOXPpOOCaY-6zyBVFY8SNl8CuNarR9aUvl-InLu8DfFGQ",
  e: "AQAB"
};

/**
 * Private key for signing mock id tokens.
 * openssl req -x509 -newkey rsa:2048 -keyout key.pem -out cert.pem -days 36500 -nodes
 * openssl rsa -in key.pem -out key1.pem (to PKCS#1)
 * pem-jwk key1.pem > key.jwk
 *
const MOCK_IDP_PRIVKEY_PEM =
  "-----BEGIN RSA PRIVATE KEY-----" +
  "MIIEpAIBAAKCAQEAz3iSskkRv/ASMLkaOTxfGd9N0wYDE0jH1bi2rZCM88a2kDxk" +
  "HoyE6Mg+PyMp3tX4O75puETvINVeVk+6+Fo0XgU9+EH1FuKMZHz0jNetLWEUqi/H" +
  "PzKQpbd50mHv7b+VZguaOd5Bgrfs63aQP2sMB40e9pWzDF/DzlP0t4l5ZMLUrPth" +
  "jWM2CIkT8zbAYrU/9gdNTgja2DZekqp15Veg1OodewSiEyRh/fj+//e8zoeSjuOl" +
  "rzDTgpdJ3w9Lmull5mQbWYoHlxh+2vOF1LTKymIuFS/6zOGsy68vb3ezgGOXPpOO" +
  "CaY+6zyBVFY8SNl8CuNarR9aUvl+InLu8DfFGQIDAQABAoIBAQCU3t3UlF3qLie/" +
  "UnMgstNCy1samuvGAKl0EDD05g5eNi513P+yc/5C0rznrSG0RjDzHbTahoxYMQKa" +
  "SGfPIVsGRBitdRaar0EyMf+AuJoJP+oGHHaKQIxOfRMrBR+Wp6xaJuUptPOpCrU0" +
  "WEU1XGirWnXnVo+9x1HHUbL9p+W1iFSO+YPyuPnatcvVGLf59nR+gPSt2GRZ+w71" +
  "u4sx4hka/Yex3ozSrXHrUrZsglrpTMQxaT9TeFfLhMg3GIhTaUWxjzKFeT563eZW" +
  "favj71mT4jDK1OCM/H/gzVKpOOA36HIgy/OykdGSUL3OLpWzJaBLvYqQ0KnpMgbR" +
  "P9KiCzChAoGBAPWSPW76CGsO9rYom46rtfW0q32NoHsVFPm2lhyC6GXtOizNAm+B" +
  "MoicKvC5XaRlYz5EWfYwrwW1FrSFZM2VxoSc0gEjD9PKbOgr6plBUcYn4VK5bjkJ" +
  "4luzgsfccAnY3TXwsvoBx4E/1gE/OlpA+tf7ToTTmj5INK6P+JXA1eddAoGBANhI" +
  "Hv6a59aos1pTBlgUObBJ4vl9gi01h5RvJXhlP1G9xD3l1r3LQWxfDAHu+W4nAy3m" +
  "ic/p3EtyQtdfkCArV887MbmXtzpBVvPpfYRtWYQRPgk46FAu3ateBY+BzutsDaLw" +
  "UdmfFGIgO8QPP9AI9k15q8YasoHBNmlzUzVHTaTtAoGBAOB04I4VH6irLrlk+Ho5" +
  "XMHOd4j08KBPrrJoP2CkDsNLSAc3A2jj0EqpY15QL9VZjpKVaxzpdrBiyvQJkQ+Q" +
  "PLltlU3QvGC6/zxfF7koyROz0/IG50ZfUHS86WbAh++YNulcrlpxUedDb9oH7tAb" +
  "4KuVWjlyJ+ORoAWGNpK6hUlJAoGAGynbsrBz47Lma7a3ukI4CJsPBbSsr87kS1E2" +
  "TIuyyyphRkDimo23lsvNL0T7UMlXjeFBd7pwb41XNcOLz26CJcXSdfPHEGr6Z8Hw" +
  "gLxXHM4pk3DxRHjOYGJ7ok10Vn8Iq2kxbnqbTcOsnfAoXJFzlcFtQIFD4CYLFm1k" +
  "ivF8n/UCgYABEgRE867ZgiCC2OTKRoNfSUVf2i+ga7iheGGWRrAoLi9vSK3KsgC5" +
  "tHoQnqoO390lRd7ylPxLAEYSfM+YtanTed23Xo+i8rz/cQXgh04k/IgnAIK4hazL" +
  "ALPglTtbKT6xbb22yfsFromkKB+vVlil+JlDEUIoV17KRd6kRC6wrw==" +
  "-----END RSA PRIVATE KEY-----";
  */
const MOCK_ID_PRIVKEY_JWK: any = {
  kty: "RSA",
  n:
    "z3iSskkRv_ASMLkaOTxfGd9N0wYDE0jH1bi2rZCM88a2kDxkHoyE6Mg-PyMp3tX4O75puETvINVeVk-6-Fo0XgU9-EH1FuKMZHz0jNetLWEUqi_HPzKQpbd50mHv7b-VZguaOd5Bgrfs63aQP2sMB40e9pWzDF_DzlP0t4l5ZMLUrPthjWM2CIkT8zbAYrU_9gdNTgja2DZekqp15Veg1OodewSiEyRh_fj-__e8zoeSjuOlrzDTgpdJ3w9Lmull5mQbWYoHlxh-2vOF1LTKymIuFS_6zOGsy68vb3ezgGOXPpOOCaY-6zyBVFY8SNl8CuNarR9aUvl-InLu8DfFGQ",
  e: "AQAB",
  d:
    "lN7d1JRd6i4nv1JzILLTQstbGprrxgCpdBAw9OYOXjYuddz_snP-QtK8560htEYw8x202oaMWDECmkhnzyFbBkQYrXUWmq9BMjH_gLiaCT_qBhx2ikCMTn0TKwUflqesWiblKbTzqQq1NFhFNVxoq1p151aPvcdRx1Gy_afltYhUjvmD8rj52rXL1Ri3-fZ0foD0rdhkWfsO9buLMeIZGv2Hsd6M0q1x61K2bIJa6UzEMWk_U3hXy4TINxiIU2lFsY8yhXk-et3mVn2r4-9Zk-IwytTgjPx_4M1SqTjgN-hyIMvzspHRklC9zi6VsyWgS72KkNCp6TIG0T_SogswoQ",
  p:
    "9ZI9bvoIaw72tiibjqu19bSrfY2gexUU-baWHILoZe06LM0Cb4EyiJwq8LldpGVjPkRZ9jCvBbUWtIVkzZXGhJzSASMP08ps6CvqmUFRxifhUrluOQniW7OCx9xwCdjdNfCy-gHHgT_WAT86WkD61_tOhNOaPkg0ro_4lcDV510",
  q:
    "2Ege_prn1qizWlMGWBQ5sEni-X2CLTWHlG8leGU_Ub3EPeXWvctBbF8MAe75bicDLeaJz-ncS3JC11-QICtXzzsxuZe3OkFW8-l9hG1ZhBE-CTjoUC7dq14Fj4HO62wNovBR2Z8UYiA7xA8_0Aj2TXmrxhqygcE2aXNTNUdNpO0",
  dp:
    "4HTgjhUfqKsuuWT4ejlcwc53iPTwoE-usmg_YKQOw0tIBzcDaOPQSqljXlAv1VmOkpVrHOl2sGLK9AmRD5A8uW2VTdC8YLr_PF8XuSjJE7PT8gbnRl9QdLzpZsCH75g26VyuWnFR50Nv2gfu0Bvgq5VaOXIn45GgBYY2krqFSUk",
  dq:
    "GynbsrBz47Lma7a3ukI4CJsPBbSsr87kS1E2TIuyyyphRkDimo23lsvNL0T7UMlXjeFBd7pwb41XNcOLz26CJcXSdfPHEGr6Z8HwgLxXHM4pk3DxRHjOYGJ7ok10Vn8Iq2kxbnqbTcOsnfAoXJFzlcFtQIFD4CYLFm1kivF8n_U",
  qi:
    "ARIERPOu2YIggtjkykaDX0lFX9ovoGu4oXhhlkawKC4vb0ityrIAubR6EJ6qDt_dJUXe8pT8SwBGEnzPmLWp03ndt16PovK8_3EF4IdOJPyIJwCCuIWsywCz4JU7Wyk-sW29tsn7Ba6JpCgfr1ZYpfiZQxFCKFdeykXepEQusK8"
};

export async function getMockSignerPrivateKey(): Promise<CryptoKey> {
  return Jose.Utils.importRsaPrivateKey(MOCK_ID_PRIVKEY_JWK, "RS256");
}

export async function getMockSignerPublicKey(): Promise<CryptoKey> {
  return Jose.Utils.importRsaPublicKey(MOCK_IDP_PUBKEY_JWK, "RS256");
}
