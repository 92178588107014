import * as ActionTypes from "./actionTypes";
import { idpApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import {
  buildActionThunk,
  ensureSelectedOrgId,
  forceUndefined
} from "./actionHelpers";
import { User } from "../model/User";

/**
 * Gets all users who are members of a role.
 * @param sender Component requesting for the action
 * @param orgRoleId The role id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function listUsersInOrganizationRole(
  sender: ActionSender,
  orgRoleId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListUsersInOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.ListUsersInOrgRoleAction, User[]>(
    sender,
    ActionTypes.LIST_USERS_IN_ORG_ROLE,
    async () =>
      await idpApi.listUsersInOrganizationRole(orgRoleId, orgIdOrDefault),
    (type, users) => ({
      type,
      users: forceUndefined(users),
      orgRoleId,
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Adds multiple users to organization role.
 * @param sender Component requesting for the action
 * @param orgRoleId The role id
 * @param userIds Array of user ids to add
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function addUsersToOrgRole(
  sender: ActionSender,
  orgRoleId: string,
  userIds: string[],
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.AddUsersToOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.AddUsersToOrgRoleAction, void>(
    sender,
    ActionTypes.ADD_USERS_TO_ORG_ROLE,
    async () =>
      await idpApi.addUsersToOrganizationRole(
        orgRoleId,
        userIds,
        orgIdOrDefault
      ),
    type => ({
      type,
      orgRoleId,
      userIds,
      orgId: orgIdOrDefault
    })
  );
}

/**
 * remove multiple users from organization role.
 * @param sender Component requesting for the action
 * @param orgRoleId The role id
 * @param userIds Array of user ids to remove
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function removeUsersFromOrgRole(
  sender: ActionSender,
  orgRoleId: string,
  userIds: string[],
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.RemoveUsersFromOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.RemoveUsersFromOrgRoleAction, void>(
    sender,
    ActionTypes.REMOVE_USERS_FROM_ORG_ROLE,
    async () =>
      await idpApi.removeUsersFromOrganizationRole(
        orgRoleId,
        userIds,
        orgIdOrDefault
      ),
    type => ({
      type,
      orgRoleId,
      userIds,
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Add user to organization role.
 * @param sender Component requesting for the action
 * @param orgRoleId The role id
 * @param userId User id to add
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function addUserToOrgRole(
  sender: ActionSender,
  orgRoleId: string,
  userId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.AddUserToOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.AddUserToOrgRoleAction, void>(
    sender,
    ActionTypes.ADD_USER_TO_ORG_ROLE,
    async () =>
      await idpApi.addUserToOrganizationRole(orgRoleId, userId, orgIdOrDefault),
    type => ({
      type,
      orgRoleId,
      userId,
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Remove user from organization role.
 * @param sender Component requesting for the action
 * @param orgRoleId The role id
 * @param userId User id to remove
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function removeUserFromOrgRole(
  sender: ActionSender,
  orgRoleId: string,
  userId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.RemoveUserFromOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.RemoveUserFromOrgRoleAction, void>(
    sender,
    ActionTypes.REMOVE_USER_FROM_ORG_ROLE,
    async () =>
      await idpApi.removeUserFromOrganizationRole(
        orgRoleId,
        userId,
        orgIdOrDefault
      ),
    type => ({
      type,
      orgRoleId,
      userId,
      orgId: orgIdOrDefault
    })
  );
}
