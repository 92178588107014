import * as ActionTypes from "../actions/actionTypes";
import { OrgRoleIdsByOrgId } from "../store/OrganizationRoleState";

export default function orgOrgRoleIds(
  state: OrgRoleIdsByOrgId,
  action: ActionTypes.AppAction
): OrgRoleIdsByOrgId | null {
  const currentState = state || ({} as OrgRoleIdsByOrgId);
  switch (action.type) {
    case ActionTypes.LIST_ORG_ROLES:
      const listOrgRoles = action as ActionTypes.ListOrgRolesAction;
      const newOrgRoleIds = listOrgRoles.roles.map(role => role.id as string);
      return { ...currentState, [listOrgRoles.orgId]: newOrgRoleIds };
    case ActionTypes.CREATE_ORG_ROLE:
      const createOrgRole = action as ActionTypes.CreateOrgRoleAction;
      const beforeCreate = currentState[createOrgRole.orgId] || [];
      const afterCreate = [...beforeCreate, createOrgRole.role.id as string];
      return {
        ...currentState,
        [createOrgRole.orgId]: afterCreate
      };
    case ActionTypes.DELETE_ORG_ROLE:
      const delOrgRole = action as ActionTypes.DeleteOrgRoleAction;
      const beforeDelete = currentState[delOrgRole.orgId] || [];
      const afterDelete = beforeDelete.filter(
        roleId => roleId !== delOrgRole.orgRoleId
      );
      return {
        ...currentState,
        [delOrgRole.orgId]: afterDelete
      };
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
