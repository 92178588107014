import { ORG_1_INVITATIONS } from "./org1Invitations";
import { ORG_2_INVITATIONS } from "./org2Invitations";
import { APP_ORGS } from "./appOrganizations";
import { OrganizationGroupInvitation } from "../../../model/OrganizationGroupInvitation";

export const ALL_INVITATIONS: OrganizationGroupInvitation[][] = [
  ORG_1_INVITATIONS,
  ORG_2_INVITATIONS
];

export const ALL_INVITATIONS_BY_ORG_ID = {
  [APP_ORGS[0].id as string]: ORG_1_INVITATIONS,
  [APP_ORGS[1].id as string]: ORG_2_INVITATIONS
};
