import * as ActionTypes from "../actions/actionTypes";
import { OrgInvitationsById } from "../store/OrganizationInvitationState";

export default function orgInvitations(
  state: OrgInvitationsById,
  action: ActionTypes.AppAction
): OrgInvitationsById | null {
  const currentState = state || ({} as OrgInvitationsById);
  switch (action.type) {
    case ActionTypes.LIST_ORG_ORG_GROUP_INVITATIONS:
      const listOrgInvitations = action as ActionTypes.ListOrgOrgGroupInvitationsAction;
      const newOrgInvitationsById = listOrgInvitations.invitations.reduce<
        OrgInvitationsById
      >((map, invitation) => {
        map[invitation.id as string] = invitation;
        return map;
      }, {});
      return { ...currentState, ...newOrgInvitationsById };
    case ActionTypes.GET_ORG_ORG_GROUP_INVITATION:
      const getOrgInvitation = action as ActionTypes.GetOrgOrgGroupInvitationAction;
      return {
        ...currentState,
        [getOrgInvitation.invitation.id as string]: getOrgInvitation.invitation
      };
    case ActionTypes.CREATE_ORG_GROUP_INVITATION:
      const createOrgInvitation = action as ActionTypes.CreateOrgGroupInvitationAction;
      return {
        ...currentState,
        [createOrgInvitation.invitation
          .id as string]: createOrgInvitation.invitation
      };
    case ActionTypes.SEND_ORG_GROUP_INVITATION:
      const sendOrgInvitation = action as ActionTypes.SendOrgGroupInvitationAction;
      return {
        ...currentState,
        [sendOrgInvitation.invitation
          .id as string]: sendOrgInvitation.invitation
      };
    case ActionTypes.REVOKE_ORG_GROUP_INVITATION:
      const revokeOrgInvitation = action as ActionTypes.RevokeOrgGroupInvitationAction;
      return {
        ...currentState,
        [revokeOrgInvitation.invitation
          .id as string]: revokeOrgInvitation.invitation
      };
    case ActionTypes.DELETE_ORG_GROUP_INVITATION:
      const delOrgInvitation = action as ActionTypes.DeleteOrgGroupInvitationAction;
      const { [delOrgInvitation.invitationId]: _, ...remaining } = currentState;
      return remaining;
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
