import { APP_ORGS } from "./appOrganizations";
import { ORG_1_GROUPS, ORG_1_GROUP_MEMBER_IDS_BY_GROUP_ID } from "./org1Groups";
import { ORG_2_GROUPS, ORG_2_GROUP_MEMBER_IDS_BY_GROUP_ID } from "./org2Groups";

export const ALL_GROUPS = [...ORG_1_GROUPS, ...ORG_2_GROUPS];

export const ALL_GROUPS_BY_ORG_ID = {
  [APP_ORGS[0].id as string]: ORG_1_GROUPS,
  [APP_ORGS[1].id as string]: ORG_2_GROUPS
};

export const ALL_GROUP_MEMBER_IDS_BY_GROUP_ID = {
  ...ORG_1_GROUP_MEMBER_IDS_BY_GROUP_ID,
  ...ORG_2_GROUP_MEMBER_IDS_BY_GROUP_ID
};
