import { OrganizationRole } from "../../../model/OrganizationRole";
import { ORG_1_USERS } from "./org1Members";
import { PERMISSION_ANY } from "./permissions";

/*
 * Organization roles of mock org 1, "Acme Inc".
 */

const ORG_1_ROLE_ADMIN: OrganizationRole = {
  id: "6b63430b-6f54-4419-9619-86703d5cb651",
  designator: "orgadmin",
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-04-15T10:00:00.000Z",
  name: "OrgAdmins of Acme Inc",
  description: "Organization administrators"
};

const INITIAL_ORG_1_ROLE_ADMIN_MEMBER_IDS: string[] = [
  ORG_1_USERS[0].id as string,
  ORG_1_USERS[2].id as string
];

const INITIAL_ORG_1_ROLE_ADMIN_PERMISSION_IDS: {
  id: string;
  actions: string[];
}[] = [{ id: PERMISSION_ANY.id as string, actions: ["*"] }];

export const ORG_1_ROLES: OrganizationRole[] = [ORG_1_ROLE_ADMIN];

export const ORG_1_ROLE_MEMBER_IDS_BY_ROLE_ID = {
  [ORG_1_ROLE_ADMIN.id as string]: INITIAL_ORG_1_ROLE_ADMIN_MEMBER_IDS
};

export const ORG_1_ROLE_PERMISSION_IDS_BY_ROLE_ID = {
  [ORG_1_ROLE_ADMIN.id as string]: INITIAL_ORG_1_ROLE_ADMIN_PERMISSION_IDS
};
