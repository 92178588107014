import * as ActionTypes from "./actionTypes";
import { ActionSender } from "../model/ActionSender";
import { generateRandomString } from "../util/random";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AppState } from "../store/AppState";

function generateOperationId(): string {
  return new Date().getTime() + "-" + generateRandomString(8);
}

export function startInProgress<A extends ActionTypes.AppAction>(
  sender: ActionSender,
  operationType: string,
  action: A,
  operationId: string = generateOperationId()
): ThunkAction<
  Promise<ActionTypes.AddInProgressAction<A>>,
  AppState,
  unknown,
  ActionTypes.AddInProgressAction<A>
> {
  return async (
    dispatch: ThunkDispatch<
      AppState,
      unknown,
      ActionTypes.AddInProgressAction<A>
    >
  ): Promise<ActionTypes.AddInProgressAction<A>> => {
    return dispatch({
      type: ActionTypes.ADD_IN_PROGRESS,
      operation: {
        operationId,
        operationType,
        actionSender: sender,
        action
      }
    });
  };
}

export function clearInProgress(
  operationId: string
): ActionTypes.ClearInProgressAction {
  return {
    type: ActionTypes.CLEAR_IN_PROGRESS,
    operationId
  };
}

export function clearAllInProgressInfos(): ActionTypes.ClearAllInProgressInfosAction {
  return {
    type: ActionTypes.CLEAR_ALL_IN_PROGRESS_INFOS
  };
}
