import { OrganizationGroup } from "../../../model/OrganizationGroup";
import { ORG_2_USERS } from "./org2Members";

/*
 * Organization groups of mock org 2, "Acne Ltd".
 */

const ORG_2_GROUP_EMPLOYEES: OrganizationGroup = {
  id: "7bc4de50-2156-4be2-b7c6-03a5efc7a94d",
  authorId: "32f3bea7-1a1c-461e-ac3c-253e2afbd49d",
  created: "2020-04-14T08:00:00.000Z",
  name: "Employees of Acne Ltd",
  type: "employees",
  description: "Group containing all employees of Acne Ltd"
};

const INITIAL_ORG_2_GROUP_EMPLOYEES_MEMBER_IDS: string[] = ORG_2_USERS.map(
  user => user.id as string
);

export const ORG_2_GROUPS: OrganizationGroup[] = [ORG_2_GROUP_EMPLOYEES];

export const ORG_2_GROUP_MEMBER_IDS_BY_GROUP_ID = {
  [ORG_2_GROUP_EMPLOYEES.id as string]: INITIAL_ORG_2_GROUP_EMPLOYEES_MEMBER_IDS
};
