import { User } from "../../../model/User";

/*
 * Users of mock org 2, "very big american corporation".
 */

const ORG_2_USER_1: User = {
  id: "226c7391-77ac-45cd-bdc4-acf17760c0fe",
  created: "2020-04-10T08:00:00.000Z",
  email: "JanetVFugate@vbcoa.com",
  firstName: "Janet",
  lastName: "Fugate",
  validFrom: "2020-04-10T08:00:00.000Z"
};

export const ORG_2_USERS: User[] = [ORG_2_USER_1];
