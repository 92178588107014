import * as ActionTypes from "../actions/actionTypes";
import { OrgsById } from "../store/OrganizationState";

export default function organizations(
  state: OrgsById,
  action: ActionTypes.AppAction
): OrgsById | null {
  const currentState = state || ({} as OrgsById);
  switch (action.type) {
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    case ActionTypes.LOAD_ORGS:
      const loadOrgsCompleted = action as ActionTypes.LoadOrgsAction;
      const newOrgsById = loadOrgsCompleted.organizations.reduce<OrgsById>(
        (map, org) => {
          map[org.id as string] = org;
          return map;
        },
        {}
      );
      return { ...currentState, ...newOrgsById };
    case ActionTypes.GET_ORG:
      const getOrg = action as ActionTypes.GetOrgAction;
      return {
        ...currentState,
        [getOrg.organization.id as string]: getOrg.organization
      };
    case ActionTypes.REPLACE_ORG:
      const replaceOrg = action as ActionTypes.ReplaceOrgAction;
      return {
        ...currentState,
        [replaceOrg.organization.id as string]: replaceOrg.organization
      };
    case ActionTypes.SET_MFA_REQUIRED:
    default:
      return state || null;
  }
}
