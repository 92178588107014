import { Authentication } from "../model/Authentication";
import * as ActionTypes from "../actions/actionTypes";

export default function authentication(
  state: Authentication,
  action: ActionTypes.AppAction
): Authentication | null {
  switch (action.type) {
    case ActionTypes.SET_AUTHN:
      const setAuthnStatus = action as ActionTypes.SetAuthnAction;
      return setAuthnStatus.authn;
    case ActionTypes.CLEAR_AUTHN_STATUS:
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
