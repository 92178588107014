import * as ActionTypes from "../actions/actionTypes";
import { OrgRolesById } from "../store/OrganizationRoleState";

export default function orgRoles(
  state: OrgRolesById,
  action: ActionTypes.AppAction
): OrgRolesById | null {
  const currentState = state || ({} as OrgRolesById);
  switch (action.type) {
    case ActionTypes.LIST_ORG_ROLES:
      const listOrgRoles = action as ActionTypes.ListOrgRolesAction;
      const newOrgRolesById = listOrgRoles.roles.reduce<OrgRolesById>(
        (map, role) => {
          map[role.id as string] = role;
          return map;
        },
        {}
      );
      return { ...currentState, ...newOrgRolesById };
    case ActionTypes.CREATE_ORG_ROLE:
      const createOrgRole = action as ActionTypes.CreateOrgRoleAction;
      return {
        ...currentState,
        [createOrgRole.role.id as string]: createOrgRole.role
      };
    case ActionTypes.REPLACE_ORG_ROLE:
      const replaceOrgRole = action as ActionTypes.ReplaceOrgRoleAction;
      return {
        ...currentState,
        [replaceOrgRole.role.id as string]: replaceOrgRole.role
      };
    case ActionTypes.GET_ORG_ROLE:
      const getOrgRole = action as ActionTypes.GetOrgRoleAction;
      return {
        ...currentState,
        [getOrgRole.role.id as string]: getOrgRole.role
      };
    case ActionTypes.DELETE_ORG_ROLE:
      const delOrgRole = action as ActionTypes.DeleteOrgRoleAction;
      const { [delOrgRole.orgRoleId]: _, ...remaining } = currentState;
      return remaining;
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
